
























import { Action, Getter, Mutation, State } from 'vuex-class'
import { Component, Vue } from 'vue-property-decorator'
import GTMEvent from '@/Common/interfaces/GTMEvent'

@Component({
  components: {},
})
export default class CheckInComplete extends Vue {
  @Action('location/clearLocation') clearCurrentLocation
  @Action('location/fetchCurrentLocation') fetchCurrentLocation
  @Action('location/fetchDefaultLocation') fetchDefaultLocation
  @Action('trackEvent') trackEvent
  @Getter('location/currentLocation') currentLocation
  @Getter('location/defaultLocation') defaultLocation
  @Getter('location/previousLocation') previousLocation
  @Getter('settings/languageSettings') language
  @Mutation('location/setCurrentLocation') setCurrentLocation
  @Mutation('setPageLoading') setPageLoading
  @State('pageLoading') pageLoading

  private onReturnHome() {
    this.setPageLoading(true)
    if (!this.currentLocation && this.currentLocation.isEditProfileLocation) {
      if (this.defaultLocation) {
        this.setCurrentLocation(this.defaultLocation)
      }
    } else {
      this.clearCurrentLocation()
    }
    this.$router.push({ name: 'home' }).catch(() => {
      // .. Nothing yet
    })
  }

  private onRedoCheckin() {
    this.setPageLoading(true)
    if (this.previousLocation) {
      if (this.previousLocation) {
        this.setCurrentLocation(this.previousLocation)
      }
    } else {
      this.clearCurrentLocation()
    }
    this.$router.push({ name: 'home' }).catch(() => {
      // .. Nothing yet
    })
  }

  public async mounted() {
    const signatureEvent = {
      event: 'arrival_event',
      category: 'Check-In',
      action: 'Check-In Complete',
      noninteraction: false,
    }
    this.trackEvent(signatureEvent as GTMEvent)

    if (!this.currentLocation) {
      await this.fetchCurrentLocation()
    }
    if (!this.defaultLocation) {
      await this.fetchDefaultLocation()
    }
  }
}
